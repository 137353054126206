@import "variables";

@import "~@visualmedia/cookie-notice";

@import "~bootstrap";
@import "~@chenfengyuan/datepicker/src/css/datepicker";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome",
		"~@fortawesome/fontawesome-pro/scss/solid",
		"~@fortawesome/fontawesome-pro/scss/regular",
		"~@fortawesome/fontawesome-pro/scss/light",
		"~@fortawesome/fontawesome-pro/scss/brands";

@import "~slick-carousel/slick/slick",
		"~slick-lightbox/src/styles/slick-lightbox",
        "~lity/dist/lity";

@import "~swiper/swiper";

@import "core/mixins",
		"core/fonts",
		"core/layout",
		"core/custom";

@import "modules/form",
		"modules/button",
		"modules/card",
		"modules/header",
		"modules/menu",
		"modules/aanbod",
		"modules/breadcrumb",
		"modules/usp",
		"modules/detail",
		"modules/dashboard",
        "modules/customform-elements",
		"modules/faq",
		"modules/footer",
		"modules/visual",
		"modules/modal",
		"modules/error",
		"modules/calendar";
